import React from "react";
import { StyleSheet, Image, Pressable, Text, Platform } from "react-native";

// Import images as modules
import chatIcon from "./assets/icons/chat.png";
import closeIcon from "./assets/icons/close.png";
import settingsIcon from "./assets/icons/settings.png";
import questionIcon from "./assets/icons/question-mark-chat-bubble.png";
import applicationIcon from "./assets/icons/application.png";
import arrowIcon from "./assets/icons/arrow.png";
import moneyIcon from "./assets/icons/money.png";

export const MyButton = ({
  platform,
  tab,
  type,
  fontWeight,
  fontSize,
  flex,
  text,
  marginTop,
  action,
  selected,
  textAlign,
  width,
}) => {
  const styles = StyleSheet.create({
    wrapperCustom: {
      ...Platform.select({
        web: {
          paddingLeft: "1%",
          flexDirection: "row",
          backgroundColor: null,
          alignSelf: "center",
          alignItems: "center",
          marginTop: marginTop || 0,
          width: width || "auto",
          height: 50,
        },
        default: {
        flex: flex ? flex : null,
        flexDirection: "row",
        backgroundColor: null,
        alignSelf: "center",
        alignItems: "center",
        borderRadius: 15, // Increase border radius for a more Apple-like look
        marginTop: marginTop || 0,
        width: width || "auto",
        height: 50,
        paddingLeft: "3%",
        // justifyContent: platform !== "Web" ? "center" : null,
      borderRadius: 8,
      padding: 1,
        }
      })
    },
  });
  let imgSource;
  if (type === "back") {
    imgSource = arrowIcon;
  } else {
    if (tab === 1) {
      imgSource = chatIcon;
    } else if (tab === 2) {
      imgSource = questionIcon;
    } else if (tab === 4) {
      imgSource = applicationIcon;
    } else if (tab === 5) {
      imgSource = settingsIcon;
    } else if (tab === 3) {
      imgSource = moneyIcon;
    } else {
      imgSource = closeIcon;
    }
  }

  return (
    <Pressable
      onPress={() => {
        action();
      }}
      style={[
        styles.wrapperCustom,
      ]}
    >
      <Image
        source={imgSource}
        style={{
          width: platform === "Mobile" ? 30 : 24,
          height: platform === "Mobile" ? 30 : 24,
          alignSelf: "center",
          alignContent: "center",
          marginRight: "8%",
        }}
      />
      <Text
        style={{
          textAlign: textAlign ? textAlign : "center",
          alignSelf: "center",
          fontSize: fontSize ? 16 : 12,
          fontWeight: fontWeight ? fontWeight : "normal",
          color: "black",
          fontFamily: "System", // Use San Francisco font on Apple devices
        }}
      >
        {text}
      </Text>
    </Pressable>
  );
};
