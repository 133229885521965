import React from "react";
import {
  Dimensions,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { ScrollView } from "react-native";
// todo: should be react native web in web (above line)
const QuestionList = ({
  timezone,
  platform,
  selectedQuestion,
  questions,
  setSelectedQuestion,
  setSelectedQuestionName,
  setOpenQuestion,
  openQuestion,
}) => {
  // console.log("questions " + questions.length)
  const screenHeight = Dimensions.get("window").height;
  function customSort(a, b) {
    // console.log("custom sorting")
    const unreadAndActiveA = !a.read && (a?.status === "Active" || a?.subscriber === true || a?.subscriber === "on_site");
    const unreadAndActiveB = !b.read && (b?.status === "Active" || b?.subscriber === true || b?.subscriber === "on_site");
    const readAndActiveA = a.read && (a?.status === "Active" || a?.subscriber === true || a?.subscriber === "on_site");
    const readAndActiveB = b.read && (b?.status === "Active" || b?.subscriber === true || b?.subscriber === "on_site");
    // Unread and active questions have highest priority
    if (unreadAndActiveA && !unreadAndActiveB) return -1;
    if (!unreadAndActiveA && unreadAndActiveB) return 1;
    if (unreadAndActiveA && unreadAndActiveB)
      return b?.lastMessage?.timestamp - a?.lastMessage?.timestamp;

    // Read and active questions have next highest priority
    if (readAndActiveA && !readAndActiveB) return -1;
    if (!readAndActiveA && readAndActiveB) return 1;
    if (readAndActiveA && readAndActiveB)
      return b?.lastMessage?.timestamp - a?.lastMessage?.timestamp;

    // For all other cases, just sort based on timestamp
    return b?.lastMessage?.timestamp - a?.lastMessage?.timestamp;
  }

  const sortedQuestions = questions.sort(customSort);

  return (
    <ScrollView
      style={{
        maxHeight: screenHeight,
        marginTop: platform === "Web" ? '1%' : '10%',
      }}
      contentContainerStyle={{
        paddingHorizontal: '5%', // Ensure consistent horizontal padding
        alignItems: 'center', // Center content horizontally
      }}
    >
      {sortedQuestions.length > 0 ? (
        sortedQuestions.map((question) => {
          return question.lastMessage !== undefined ? (
            <TouchableOpacity
              disabled={question.status === "Cancelled" ? true : false}
              key={question.id}
              style={[
                styles.questionItem,
                { opacity: question.status === "Cancelled" ? 0.5 : 1 },
              ]}
              onPress={() => {
                if (question.status === "Cancelled") return;
                setOpenQuestion(!openQuestion);
                setSelectedQuestionName(question.sender.name);
                setSelectedQuestion(question.id);
              }}
            >
              <View
                style={
                  openQuestion && selectedQuestion === question.id
                    ? styles.selected
                    : styles.info
                }
              >
                <View style={styles.headerContainer}>
                  <View style={[styles.nameContainer, { flexDirection: "row" }]}>
                    <Text style={styles.name}>{question.sender.name}</Text>
                    {!question.read && <View style={styles.unreadIndicator} />}
                  </View>
                  <Text style={styles.time}>
                    {!isNaN(new Date(question?.lastMessage?.timestamp).getTime())
                      ? new Date(
                        Number(question?.lastMessage?.timestamp)
                      ).toLocaleString("ru-RU", {
                        day: "2-digit",
                        month: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZone: timezone,
                      })
                      : null}
                  </Text>
                </View>
                <Text style={styles.message} numberOfLines={2}>
                  {question.lastMessage.text}
                </Text>
              </View>
            </TouchableOpacity>
          ) : null;
        })
      ) : (
        <TouchableOpacity style={styles.info}>
          <Text>Пока нет вопросов.</Text>
        </TouchableOpacity>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  questionItem: {
    flexDirection: "row",
    width: "90%", // Define the width to help center the items
    alignItems: "center",
    marginBottom: "2%", // Reduced vertical spacing between items
  },
  info: {
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
    width: "100%", // Maintain consistent width with questionItem
    height: "auto",
    padding: 22,
    borderRadius: 20,
    alignSelf: 'center', // Center the info boxes within their container
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  unreadIndicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "blue",
    marginRight: 8,
    marginLeft: 8,
  },
  selected: {
    backgroundColor: "#eff2f5",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px",
    width: "90%",
    height: "auto",
    padding: 22,
    borderRadius: 20,
    alignSelf: 'center', // Ensure selected style also centers correctly
  },
  name: {
    fontWeight: "bold",
    marginBottom: 3,
  },
  message: {
    color: "gray",
    maxWidth: "80%",
    lineHeight: 15,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%", // Ensure the header spans the entire width
  },
  time: {
    position: "absolute",
    right: 0,
    width: 50,
    color: "gray",
    fontSize: 8,
  },
});

export default QuestionList;