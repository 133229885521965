import { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableHighlight,
  TextInput,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  Alert
} from "react-native";
import { Keyboard } from 'react-native';
import LottieView from 'lottie-react-native';
import axios from "axios"
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { RefreshControl } from "react-native-web-refresh-control";
import { MyButton } from "./MyButton";
import { auth, db, storage } from "./db";
import "firebase/firestore";
import "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import { collection, getDoc, query, getDocs, where, doc, onSnapshot, updateDoc, arrayUnion, limit } from "firebase/firestore";
const Chat = ({
  timezone,
  setSelectedChat,
  platform,
  setOpenChat,
  selectedChat,
  selectedChatName,
  setSelectedChatName,
  psyid,
}) => {
  const { width, height } = Dimensions.get('window');
  const thresholdWidth = 768; // Typically tablet breakpoint
  const [inputHeight, setInputHeight] = useState(100);

  let firstRender;
  const scrollTimeout = useRef(null);
  const [refreshing, setRefreshing] = useState(false);
  const ChatListRef = useRef(null);
  const [messageLength, setMessageLength] = useState(0);
  const screenHeight = Dimensions.get("window").height;
  const [activeChat, setActiveChat] = useState([]);
  const [text, setText] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const showSelectionAlert = () => {
    const options = [
      { text: 'Файл', onPress: () => handleSelectFile() },
      { text: 'Изображение', onPress: () => handleSelectImage() },
      { text: 'Отмена', style: 'cancel' },
    ];

    Alert.alert(
      "Выберите тип файла",
      "Вы хотите отправить изображение или файл?",
      options,
      { cancelable: true }
    );
  };
  let DocumentPicker;

  // Check if the environment is not web
  if (Platform.OS !== 'web') {
    // Dynamically import DocumentPicker only if not in web environment
    DocumentPicker = require('react-native-document-picker').default;
  }
  
  const handleSelectImage = async () => {
    try {
      // console.log("handle select photo " + selectedChat)
      const chatDocRef = doc(db, "chats", selectedChat);
      const docSnap = await getDoc(chatDocRef);
      if (!docSnap.exists()) {
        throw new Error("No such document!");
      }
      const chatId = docSnap.data().botUid;
      // console.log("doc snap " + docSnap.data().botUid)
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (result.cancelled) {
        // console.log('Photo selection cancelled');
        return;
      }

      const { uri } = result;
      const filename = uri.split('/').pop();
      const match = /\.(\w+)$/.exec(filename);
      const fileExtension = match ? `.${match[1]}` : '.jpg';
      const storageRef = ref(storage, `photos/${filename}${fileExtension}`);
      const response = await fetch(uri);
      const blob = await response.blob();
      const task = uploadBytesResumable(storageRef, blob);

      setShowLoader(true);
      task.on('state_changed',
        snapshot => {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log(`Upload is ${percentage}% done`);
        },
        err => {
          console.error('There was an error uploading the photo:', err);
          setShowLoader(false);
          Alert.alert("Upload Error", err.message);
        },
        async () => {
          const downloadURL = await getDownloadURL(task.snapshot.ref);
          // console.log('Photo available at', downloadURL);

          const data = {
            chat_id: chatId,
            document_link: downloadURL,
          };
          const config = {
            method: "post",
            data: data,
            url: `https://leanotif.herokuapp.com/sendFileToUser`,
          };

          try {
            await axios(config);
            const newMessage = {
              id: activeChat.messages.length + 1,
              sender: "psychologist",
              text: `Психолог прислал фото:`,
              timestamp: new Date().getTime(),
            };

            await updateDoc(chatDocRef, {
              read: true,
              lastMessage: {
                sender: "psychologist",
                text: `Психолог прислал фото:`,
                timestamp: new Date().getTime(),
              },
              messages: arrayUnion(newMessage),
            });
            setShowLoader(false);
          } catch (err) {
            console.error(err);
            setShowLoader(false);
            Alert.alert("Ошибка отправки", err.message);
          }
        }
      );
    } catch (err) {
      console.error(err);
      setShowLoader(false);
      Alert.alert("Photo Selection Error", err.message);
    }
  };
  const handleSelectFileApps = async () => {
    try {
      // console.log("handle select file apps " + selectedChat);
      const chatDocRef = doc(db, "chats", selectedChat);
      const docSnap = await getDoc(chatDocRef);
      if (!docSnap.exists()) {
        throw new Error("No such document!");
      }
      const chatId = docSnap.data().botUid;
      // console.log("doc snap " + docSnap.data().botUid);

      const result = await DocumentPicker.pick({
        type: [DocumentPicker.types.allFiles],
      });

      if (result.length === 0) {
        throw new Error("No file selected!");
      }
      const file = result[0];
      const { uri } = file;
      const filename = uri.split('/').pop();
      const match = /\.(\w+)$/.exec(filename);
      const fileExtension = match ? `.${match[1]}` : '.unknown';  // Default extension if not found
      const storageRef = ref(storage, `files/${filename}${fileExtension}`);

      const response = await fetch(uri);
      const blob = await response.blob();

      const task = uploadBytesResumable(storageRef, blob);
      setShowLoader(true);

      task.on('state_changed',
        snapshot => {
          const bytesTransferred = snapshot.bytesTransferred;
          const totalBytes = snapshot.totalBytes;
          if (totalBytes > 0) {
            const percentage = (bytesTransferred / totalBytes) * 100;
            // console.log(`Upload is ${percentage.toFixed(2)}% done`);
          }
        },
        err => {
          console.error('There was an error uploading the file:', err);
          setShowLoader(false);
          Alert.alert("Upload Error", err.message);
        },
        async () => {
          const downloadURL = await getDownloadURL(task.snapshot.ref);
          console.log('File available at', downloadURL); const data = {
            chat_id: chatId,
            document_link: downloadURL,
          };
          const config = {
            method: "post",
            data: data,
            url: `https://leanotif.herokuapp.com/sendFileToUser`,
          };

          try {
            await axios(config);
            const newMessage = {
              id: activeChat.messages.length + 1,
              sender: "psychologist",
              text: `Психолог прислал файл: ${file.name}`,
              timestamp: new Date().getTime(),
            };

            await updateDoc(chatDocRef, {
              read: true,
              lastMessage: {
                sender: "psychologist",
                text: `Психолог прислал файл: ${file.name}`,
                timestamp: new Date().getTime(),
              },
              messages: arrayUnion(newMessage),
            });
            setShowLoader(false);
          } catch (err) {
            console.error(err);
            setShowLoader(false);
            Alert.alert("Ошибка отправки", err.message);
          }
        });
    } catch (err) {
      console.error(err);
      setShowLoader(false);
      Alert.alert("File Selection Error", err.message);
    }
  };
  const handleSelectFile = async () => {
    const docRef = doc(db, "chats", selectedChat);
    const docSnap = await getDoc(docRef);
    const chatId = docSnap.data().botUid;
    
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = async (event) => {
      try {
        const file = event.target.files[0];
        const storageRef = ref(storage, 'files/' + file.name);
        const task = uploadBytesResumable(storageRef, file);
        
        setShowLoader(true);
        task.on('state_changed',
          (snapshot) => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + percentage + '% done');
          }, 
          (error) => {
            console.error('There was an error uploading the file:', error);
            setShowLoader(false);
          }, 
          () => {
            getDownloadURL(task.snapshot.ref).then(async (downloadURL) => {
              console.log('File available at', downloadURL);
              const document_link = downloadURL;
              const data = {
                chat_id: chatId,
                document_link: document_link
              };
              const config = {
                method: "post",
                data: data,
                url: `https://leanotif.herokuapp.com/sendFileToUser`
              };
              try {
                await axios(config);
              } catch (err) {
                console.error(err);
                setShowLoader(false);
                alert("Ошибка отправки. " + err);
              }
              const newMessage = {
                id: activeChat.messages.length + 1,
                sender: "psychologist",
                text: "Психолог прислал файл: " + file.name,
                timestamp: new Date().getTime(),
              };
              await updateDoc(docRef, {
                read: true,
                lastMessage: {
                  sender: "psychologist",
                  text: "Психолог прислал файл: " + file.name,
                  timestamp: new Date().getTime(),
                },
                messages: [...activeChat.messages, newMessage],
              });
              setShowLoader(false);
              try {
                ChatListRef.current.scrollToEnd({ animated: true });
              } catch (e) {
                console.error(e);
              }
            });
          }
        );
      } catch (err) {
        console.error(err);
        setShowLoader(false);
        alert("Ошибка отправки. " + err);
      }
    };
  
    input.click();
  };

  const styles = StyleSheet.create({
    iconsContainer: {
      ...Platform.select({
        web: {
          flexDirection: 'column', alignItems: 'center', alignSelf: 'center', marginTop: '-1%'
        },
        default: {
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'center',
          marginTop: '-6%'
        }
      })
    },
    header: {
      ...Platform.select({
        web: {
          flexDirection: "row",
          backgroundColor: "#fafafa",
          borderBottomRightRadius: 30,
          borderBottomLeftRadius: 30,
          justifyContent: "flex-start",
          paddingVertical: 0,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000, // Ensures it stays on top of other content
        },
        default: {
          flexDirection: "row",
          backgroundColor: "#fafafa",
          borderBottomRightRadius: 30,
          borderBottomLeftRadius: 30,
          justifyContent: "flex-start",
          paddingVertical: 0,
        },
      }),
    },
    webContainer: {
      minHeight: '100vh', // Ensures full viewport height on web
    },
    webInputContainer: {
      position: 'fixed', // Keeps input at the bottom on web
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white' // Ensure the background isn't transparent
    },
    container: {
      flex: 1,
      backgroundColor: "#fafafa",
      flexDirection: 'column', // Ensures the layout is vertical
      justifyContent: 'space-between', // Pushes children to the edges of the container
    },
    messageBubble: {
      maxWidth: "80%",
      borderRadius: 20,
      padding: 10,
      margin: 10,
    },
    receiverBubble: {
      alignSelf: "flex-start",
      color: "white",
      backgroundColor: "#6665ff",
    },
    senderBubble: {
      alignSelf: "flex-end",
      color: "white",
      backgroundColor: "dodgerblue",
    },
    list: {
      flex: 1,
      ...Platform.select({
        web: {
          maxHeight: Dimensions.get("window").height * 0.8, // Adjust this value to match the height of your input container
          paddingBottom: '1%', // Ensure the input container doesn't overlap the last message
        },
        default: {}
      }),
    },
    list: {
      ...Platform.select({
        web: {
          maxHeight: width <= thresholdWidth ? 500 : height * 0.8, // Adjust height based on device
        },
        default: {}
      }),
    },
    inputContainer: {
      ...Platform.select({
        web: {
          position: 'fixed',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "white",
          zIndex: 99, // Make sure it's above other elements
        },
        default: {
          flexDirection: 'row',
          alignItems: "center",
          backgroundColor: "transparent",
          padding: 10,
          position: 'relative',
        },
      }),
    },
    input: {
      flex: 1,
      backgroundColor: "rgb(228,228,228)",
      borderRadius: 20,
      padding: 12,
      marginRight: 8,
      minHeight: 100,
      paddingRight: 40, // Avoid text overlap with the counter
    },
    sendButton: {
      marginTop: '20%',
      backgroundColor: "#6665ff",
      borderRadius: 100,
      padding: 12,
    },
    counterText: {
      position: 'absolute',
      right: 10,
      bottom: 10,
      fontSize: 10,
      color: 'black'
    }
  });

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      _keyboardDidShow,
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      _keyboardDidHide,
    );

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  const _keyboardDidShow = () => {
    ChatListRef.current?.scrollToEnd({ animated: true });
  };

  const _keyboardDidHide = () => {
    // console.log('Keyboard hidden');
  };
  const handleChangeText = (text) => {
    setMessageLength(text.length);  // update the length state
    if (text.length <= 3000) {
      setText(text);  // update your text state only if the text length is less or equal to 3000
    }
  };
  const handleContentSizeChange = (contentWidth, contentHeight) => {
    const lineHeight = 20; // You may need to adjust this based on your font size and style
    const lines = Math.ceil(contentHeight / lineHeight);

    if (lines > 5) {
      const newHeight = Math.min(100 + (lines - 5) * 20, 160);
      setInputHeight(newHeight);
    } else {
      setInputHeight(100);
    }
  };
  const [messageLimit, setMessageLimit] = useState(10);

  const onSnapshotCallback = (setActiveChat) => (newDataPassed) => {
    if (!firstRender) {
      setActiveChat((prevActiveChat) => {
        return {
          ...prevActiveChat,
          messages: [...prevActiveChat?.messages, newDataPassed],
        };
      });
    } else {
      setActiveChat((prevActiveChat) => {
        return {
          ...prevActiveChat,
          messages: newDataPassed,
        };
      });
    }
    if (firstRender === true) {
    }
  };
  async function loadChat(selectedChat, psyid) {
    firstRender = true;
    
    try {
        const docRef = doc(db, "chats", selectedChat); // Create a reference to the specific chat document
        const docSnapshot = await getDoc(docRef); // Get the document snapshot

        if (docSnapshot.exists()) {
          // console.log("snapshot exists ")
            let chatData = docSnapshot.data();
            // Assuming you want to check if the 'psyid' matches before setting active chat
            if (chatData.psyid === psyid) {
              // console.log("chat data " + chatData)
                setActiveChat(chatData);
            } else {
                // console.log("No data found for the specified psyid.");
            }
        } else {
            // console.log("No chat found with the given id.");
        }
    } catch (error) {
        // console.log("Error getting chat document: ", error);
    }
}

  const handleLoadMore = () => {
    // console.log("handle load more")
    setMessageLimit(messageLimit + 5);
  };
  useEffect(() => {
    loadChat(selectedChat, psyid);
    const chatDocRef = doc(db, "chats", selectedChat); // Create a document reference

    const unsub = onSnapshot(chatDocRef, (doc) => {
      let addNew;
      // console.log("----> on snapshot envoked ")
      if (!firstRender) {
        // console.log("not first render")
        const messages = doc.data()?.messages;
        addNew = messages ? messages[messages.length - 1] : null;
      } else {
        // console.log("first render")
        addNew = doc.data()?.messages;
      }
      onSnapshotCallback(setActiveChat)(addNew);
      try {
        setTimeout(() => {
          if (ChatListRef?.current) {
            // console.log("✅ scrolling to end")
            ChatListRef.current.scrollToEnd({ animated: true });
          } else {
            // console.log("❌ ChatListRef is null")
          }
        }, 1000);
      } catch (e) {
        // console.log("🔴 Can't scroll :(((" + e);
      }
    });

    setTimeout(() => {
      if (ChatListRef?.current) {
        ChatListRef.current.scrollToEnd({ animated: true });
      }
    }, 1000);

    return () => unsub(); // Cleanup the subscription on unmount
  }, []);

  const renderItem = ({ item, index }) => {
    const isSender = item.sender === "psychologist";
    let isNewDay = false;
    if (index === 0 || new Date(item.timestamp).setHours(0, 0, 0, 0) > new Date(activeChat.messages[index - 1].timestamp).setHours(0, 0, 0, 0)) {
      isNewDay = true;
    }

    return (
      <View>
        {isNewDay && (
          <View
            style={{
              backgroundColor: "grey",
              height: 25,
              borderRadius: 30,
              opacity: 0.5,
              width: "20%",
              marginVertical: 20,
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                alignSelf: "center",
                textAlign: "center",
                fontSize: 12,
              }}
            >
              {new Date(item.timestamp).toLocaleString("ru-RU", {
                day: "numeric",
                month: "short",
              })}
            </Text>
          </View>
        )}
        <View
          key={item.id}
          style={[
            styles.messageBubble,
            isSender ? styles.senderBubble : styles.receiverBubble,
          ]}
        >
          <Text style={[{ color: "white", fontSize: 16 }]}>
            {item.text}
          </Text>
          <Text
            style={{
              color: "white",
              alignSelf: isSender ? "flex-end" : "flex-end",
              marginTop: 5,
              fontSize: 10,
            }}
          >
            {new Date(item.timestamp).toLocaleString("it-IT", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Text>
        </View>
      </View>
    );
  };
  const fetchUserData = async () => {
    // console.log("fetching user data")
    const user = auth.currentUser;
    if (user) {
      // console.log('User logged in: ', user.uid)
      const token = await user.getIdToken(true); // Forcing a refresh of the ID token
      // console.log('Token: ', token)
      return token;
    } else {
      // console.log('No user logged in.');
      return null;
    }
  };
  const handleSend = async () => {
    // console.log("handle send " + selectedChat + " " + text);
    let textTemp = text;
    let docRef = doc(db, "chats", selectedChat); // Correctly reference the document
    try {
        let doc_data = await getDoc(docRef);
        // console.log("doc data " + doc_data.data());

        if (!doc_data.exists()) {
            throw new Error("Document does not exist!");
        }

        let pushToken = doc_data.data().pushToken;
        setText("");

        // console.log("sending message " + textTemp + " to " + selectedChat);
        let newMessage = {
            id: activeChat.messages.length + 1,
            sender: "psychologist",
            text: textTemp,
            timestamp: new Date().getTime(),
        };

        // console.log("New message: " + JSON.stringify(newMessage));
        let token = await fetchUserData();

        if (token) {
            // console.log("Token found. Selected chat: " + selectedChat);
            await updateDoc(docRef, {
                read: true,
                lastMessage: {
                    sender: "psychologist",
                    text: textTemp,
                    timestamp: new Date().getTime(),
                },
                messages: [...activeChat.messages, newMessage],
                toPay: true
            });

            // console.log("✅ Document successfully updated!");
            try {
                ChatListRef.current.scrollToEnd({ animated: true });
            } catch (e) {
                // console.log(e);
            }
        } else {
            // console.log("No token found");
            window.alert("Ошибка отправки. Попробуйте перезагрузить страницу.");
        }
    } catch (error) {
        console.error("🔴 Error updating document: ", error);
        window.alert("Ошибка отправки. Вероятно, текст слишком длинный или в нём присутствуют ссылки, не поддерживаемые телеграмом.");
        // console.log("Error sending axios request " + error);
    }
};



  if (!activeChat?.messages?.length) {
    return null;
  }
  
  if (showLoader) {
    return (
      <View
              style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
            >
              {Platform.OS!=="web" ? <LottieView
                autoPlay
                loop
                source={require('./assets/loading.json')}
                backgroundColor='transparent'
                style={{
                  width: 100,
                  height: 100,
                  // backgroundColor: 'red',
                }}
              /> : <Text>Загрузка...</Text>
              }
            </View>
    );
  }
  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : (Platform.OS === "web" ? "height" : "padding")}
      keyboardVerticalOffset={Platform.OS === "ios" ? 0 : 0}
    >
      <View style={{ flex: 1 }}>
        <View style={styles.header}>
          <MyButton
            text={selectedChatName}
            fontSize={16}
            width={110}
            type="back"
            action={() => {
              setOpenChat(false);
              setSelectedChat(null);
              setSelectedChatName("");
            }}
          />
        </View>
        <FlatList
        onScroll={(event) => {
          if (event.nativeEvent.contentOffset.y === 0) {
            handleLoadMore();
          }
        }
        }
          data={activeChat.messages.slice(-messageLimit)}
          style={styles.list}
          ref={ChatListRef}
          // refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />

        <View style={styles.inputContainer}>
          <TextInput
            multiline
            style={[{ maxHeight: height }, styles.input]}
            placeholder="Ваше сообщение..."
            value={text}
            onChangeText={handleChangeText}
            onContentSizeChange={(event) => {
              // console.log("content size changed");
              handleContentSizeChange(event.nativeEvent.contentSize.height);
            }}
            onFocus={(event) => {
              // console.log("scrolling to end");
              ChatListRef.current.scrollToEnd({ animated: true });
            }}
          />
          <View style={styles.iconsContainer}>
            <View style={styles.attachmentIconContainer}>
              <TouchableHighlight
                onPress={() => { Platform.OS == "web" ? handleSelectFile() : showSelectionAlert() }}
                style={styles.attachmentIcon}
              >
                <Image
                  source={require("./assets/icons/attachment.png")}
                  style={{ width: 32, height: 32 }}
                />
              </TouchableHighlight>
            </View>
            <TouchableHighlight
              onPress={() => handleSend()}
              style={styles.sendButton}
              underlayColor="#DDDDDD"
            >
              <Image
                source={require("./assets/icons/send.png")}
                style={{ width: 15, height: 15 }}
              />
            </TouchableHighlight>
          </View>
          <Text style={styles.counterText}>
            {`${messageLength}/3000`}
          </Text>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};
export default Chat;
