import { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableHighlight,
  TextInput,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  Keyboard
} from "react-native";
import axios from "axios"
import { RefreshControl } from "react-native-web-refresh-control";
import { MyButton } from "./MyButton";
import { auth, db } from "./db";
import "firebase/firestore";
import "firebase/storage";
import { collection, getDoc, query, getDocs, where, doc, onSnapshot, updateDoc } from "firebase/firestore";
const Question = ({
  timezone,
  setSelectedQuestion,
  platform,
  setOpenQuestion,
  selectedQuestion,
  selectedQuestionName,
  setSelectedQuestionName,
  psyid,
}) => {
  const { width, height } = Dimensions.get('window');

  // Define a threshold width to determine mobile vs desktop
  const thresholdWidth = 768; // Typically tablet breakpoint
  
  const styles = StyleSheet.create({
    header: {
      ...Platform.select({
        web: {
          flexDirection: "row",
          backgroundColor: "#fafafa",
          borderBottomRightRadius: 30,
          borderBottomLeftRadius: 30,
          justifyContent: "flex-start",
          paddingVertical: 0,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000, // Ensures it stays on top of other content
        },
        default: {
          flexDirection: "row",
          backgroundColor: "#fafafa",
          borderBottomRightRadius: 30,
          borderBottomLeftRadius: 30,
          justifyContent: "flex-start",
          paddingVertical: 0,
        },
      }),
    },
    webContainer: {
      minHeight: '100vh', // Ensures full viewport height on web
    },
    webInputContainer: {
      position: 'fixed', // Keeps input at the bottom on web
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white' // Ensure the background isn't transparent
    },
    container: {
      flex: 1,
      backgroundColor: "#fafafa",
      flexDirection: 'column', // Ensures the layout is vertical
      justifyContent: 'space-between', // Pushes children to the edges of the container
    },
    messageBubble: {
      maxWidth: "80%",
      borderRadius: 20,
      padding: 10,
      margin: 10,
    },
    receiverBubble: {
      alignSelf: "flex-start",
      color: "white",
      backgroundColor: "#6665ff",
    },
    senderBubble: {
      alignSelf: "flex-end",
      color: "white",
      backgroundColor: "dodgerblue",
    },
    list: {
      ...Platform.select({
        web: {
          maxHeight: width <= thresholdWidth ? 500 : height * 0.8, // Adjust height based on device
        },
        default: {}
      }),
    },
    inputContainer: {
      ...Platform.select({
        web: {
          position: 'fixed',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "white",
          zIndex: 99, // Make sure it's above other elements
        },
        default: {
          flexDirection: 'row',
          alignItems: "center",
          backgroundColor: "transparent",
          padding: 10,
          position: 'relative',
        },
      }),
    },
    input: {
      flex: 1,
      backgroundColor: "rgb(228,228,228)",
      borderRadius: 20,
      padding: 12,
      marginRight: 8,
      minHeight: 100,
      paddingRight: 40, // Avoid text overlap with the counter
    },
    sendButton: {
      backgroundColor: "#6665ff",
      borderRadius: 100,
      padding: 12,
    },
    counterText: {
      position: 'absolute',
      right: 10,
      bottom: 10,
      fontSize: 10,
      color: 'black'
    }
  });


  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      _keyboardDidShow,
    );

    return () => {
      keyboardDidShowListener.remove();
    };
  }, []);

  const _keyboardDidShow = () => {
    questionListRef.current?.scrollToEnd({ animated: true });
  };

  let firstRender;
  const scrollTimeout = useRef(null);
  const [messageLimit, setMessageLimit] = useState(10);
  const [refreshing, setRefreshing] = useState(false);
  const questionListRef = useRef(null);
  const [inputHeight, setInputHeight] = useState(100);
  const [messageLength, setMessageLength] = useState(0);


  const onSnapshotCallback = (setActiveQuestion) => (newDataPassed) => {
    if (!firstRender) {
      setActiveQuestion((prevActiveQuestion) => {
        return {
          ...prevActiveQuestion,
          messages: [...prevActiveQuestion?.messages, newDataPassed],
        };
      });
    } else {
      setActiveQuestion((prevActiveQuestion) => {
        return {
          ...prevActiveQuestion,
          messages: newDataPassed,
        };
      });
    }
    if (firstRender === true) {
    }
  };
  const [activeQuestion, setActiveQuestion] = useState([]);
  async function loadQuestion(selectedQuestion, psyid) {
    console.log("selected question " + selectedQuestion)
    firstRender = true;
    let tempData = [];
    try {
      const chatsRef = collection(db, "chats");
      const q = query(chatsRef, where("psyid", "==", psyid), where("uid", "==", selectedQuestion));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        tempData.push(doc.data());
      });

      if (tempData.length > 0) {
        setActiveQuestion(tempData[0]);
      } else {
        // console.log("No data found for the specified query.");
      }
    } catch (error) {
      // console.log("Error getting documents: ", error);
    }
  }
  useEffect(() => {
    loadQuestion(selectedQuestion, psyid);
    const chatDocRef = doc(db, "chats", selectedQuestion); // Create a document reference

    const unsub = onSnapshot(chatDocRef, (doc) => {
      let addNew;
      // console.log("----> on snapshot envoked ")
      if (!firstRender) {
        // console.log("not first render")
        const messages = doc.data()?.messages;
        addNew = messages ? messages[messages.length - 1] : null;
      } else {
        // console.log("first render")
        addNew = doc.data()?.messages;
      }
      onSnapshotCallback(setActiveQuestion)(addNew);
      try {
        setTimeout(() => {
          if (questionListRef?.current) {
            // console.log("✅ scrolling to end")
            questionListRef.current.scrollToEnd({ animated: true });
          } else {
            // console.log("❌ questinlistref is null")
          }
        }, 1000);
      } catch (e) {
        // console.log("🔴 Can't scroll :(((" + e);
      }
    });

    setTimeout(() => {
      if (questionListRef?.current) {
        questionListRef.current.scrollToEnd({ animated: true });
      }
    }, 1000);

    return () => unsub(); // Cleanup the subscription on unmount
  }, []);

  const [text, setText] = useState("");
  const handleChangeText = (text) => {
    setMessageLength(text.length);  // update the length state
    if (text.length <= 3000) {
      setText(text);  // update your text state only if the text length is less or equal to 3000
    }
  };
  const handleLoadMore = () => {
    // console.log("handle load more")
    setMessageLimit(messageLimit + 5);
  };
  const handleContentSizeChange = (contentWidth, contentHeight) => {
    const lineHeight = 20; // You may need to adjust this based on your font size and style
    const lines = Math.ceil(contentHeight / lineHeight);

    if (lines > 5) {
      const newHeight = Math.min(100 + (lines - 5) * 20, 160);
      setInputHeight(newHeight);
    } else {
      setInputHeight(100);
    }
  };
  const onRefresh = () => {
    setRefreshing(true);
    setTimeout(() => {
      setMessageLimit(messageLimit + 5);
      setRefreshing(false);
    }, 1000);
  };
  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken(true); // Forcing a refresh of the ID token
      return token;
    } else {
      // console.log('No user logged in.');
      return null;
    }
  };
  const handleSend = async () => {
    let textTemp = text;
    // console.log("selected question "+selectedQuestion)
    let docRef = doc(db, "chats", selectedQuestion); // Create a document reference
    let doc_data = await getDoc(docRef); // Fetch the document
    let pushToken = doc_data.data().pushToken;
    setText("");
    let newMessage = {
      id: activeQuestion.messages.length + 1,
      sender: "psychologist",
      text: textTemp,
      timestamp: new Date().getTime(),
    };
    let document = await getDoc(doc(db, "chats", selectedQuestion));
    const data = {
      chat_id: document.data().botUid,
      pushToken: pushToken,
      noBot: false,
      uid: selectedQuestion,
      text: `Получен ответ от психолога:\n\n${textTemp}`,
    };
    const urlSendMessage = `https://leanotif.herokuapp.com/sendMessageToUser`;
    const config = {
      method: "post",
      url: urlSendMessage,
      data: data,
    };
    let token = await fetchUserData();
    if (token) {
      await axios(config)
      await updateDoc(docRef, {
          read: true,
          lastMessage: {
              sender: "psychologist",
              text: textTemp,
              timestamp: new Date().getTime(),
          },
          messages: [...activeQuestion.messages, newMessage],
          toPay: true
      });
      // console.log("✅ Document successfully updated!");
      try {
          questionListRef.current.scrollToEnd({ animated: true });
      } catch (e) {
          // console.log(e);
      }
    } else {
      // console.log("No token found");
      window.alert("Ошибка отправки. Попробуйте перезагрузить страницу.");
    }
  };

  const renderItem = ({ item, index }) => {
    const isSender = item.sender === "psychologist";
    let isNewDay = false;
    if (index === 0 || new Date(item.timestamp).setHours(0, 0, 0, 0) > new Date(activeQuestion.messages[index - 1].timestamp).setHours(0, 0, 0, 0)) {
      isNewDay = true;
    }

    return (
      <View style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: isSender ? "flex-end" : "flex-start",
        flex: 1,
      }}>
        {isNewDay && (
          <View
            style={{
              backgroundColor: "grey",
              height: 25,
              borderRadius: 30,
              opacity: 0.5,
              width: "20%",
              marginVertical: 20,
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                alignSelf: "center",
                textAlign: "center",
                fontSize: 12,
              }}
            >
              {new Date(item.timestamp).toLocaleString("ru-RU", {
                day: "numeric",
                month: "short",
              })}
            </Text>
          </View>
        )}
        <View
          key={item.id}
          style={[
            styles.messageBubble,
            isSender ? styles.senderBubble : styles.receiverBubble,
          ]}
        >
          <Text style={[{ color: "white", fontSize: 16 }]}>
            {item.text}
          </Text>
          <Text
            style={{
              color: "white",
              alignSelf: isSender ? "flex-end" : "flex-end",
              marginTop: 5,
              fontSize: 10,
            }}
          >
            {new Date(item.timestamp).toLocaleString("it-IT", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Text>
        </View>
      </View>
    );
  };

  if (!activeQuestion?.messages?.length) {
    return null;
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : (Platform.OS === "web" ? "height" : "padding")}
      keyboardVerticalOffset={Platform.OS === "ios" ? 0 : 0}
    >

      <View style={{ flex: 1 }}>
        <View style={styles.header}>
          <MyButton
            text={selectedQuestionName}
            fontSize={16}
            width={110}
            type="back"
            action={() => {
              setOpenQuestion(false);
              setSelectedQuestion(null);
              setSelectedQuestionName("");
            }}
          />
        </View>
        <FlatList
          onScroll={(event) => {
            if (event.nativeEvent.contentOffset.y === 0) {
              handleLoadMore();
            }
          }
          }
          data={activeQuestion.messages}
          style={styles.list}
          ref={questionListRef}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
       {!activeQuestion.read && <View style={styles.inputContainer}>
          <TextInput
            multiline
            style={[{ maxHeight: inputHeight }, styles.input]}
            placeholder="Ваше сообщение..."
            value={text}
            onChangeText={handleChangeText}
            onContentSizeChange={(event) => {
              // console.log("content size changed");
              handleContentSizeChange(event.nativeEvent.contentSize.height);
            }}
            onFocus={(event) => {
              // console.log("scrolling to end");
              questionListRef.current.scrollToEnd({ animated: true });
            }}
          />
          <TouchableHighlight
            onPress={handleSend}
            style={styles.sendButton}
            underlayColor="#DDDDDD"
          >
            <Image
              source={require("./assets/icons/send.png")}
              style={{ width: 15, height: 15 }}
            />
          </TouchableHighlight>
          <Text style={styles.counterText}>
            {`${messageLength}/3000`}
          </Text>
        </View>}
      </View>
    </KeyboardAvoidingView>
  );

};


export default Question;