import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { SafeAreaView } from "react-native";
import { MyButton } from "./MyButton";
import ChatList from "./ChatList";
import Chat from "./Chat";
import QuestionList from "./QuestionList";
import Question from "./Question";
import Settings from "./Settings";
import PaymentList from "./PaymentList";

const MainScreen = ({
  platform,
  timezone,
  psyid,
  chats,
  questions,
  totalToPayChats,
  setLogged,
  totalToPayQuestions
}) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [openChat, setOpenChat] = useState(false);
  const [selectedQuestionName, setSelectedQuestionName] = useState();
  const [selectedChatName, setSelectedChatName] = useState();
  const [selectedChatStatus, setSelectedChatStatus] = useState();
  const [openQuestion, setOpenQuestion] = useState(false);
  const [selectedChat, setSelectedChat] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  return (
    <SafeAreaView style={styles.container}>
      {!openChat && !openQuestion && (
        <View style={styles.tabBar}>
          <TouchableOpacity onPress={() => {
            setOpenChat(false);
            setActiveTab("tab1");
            setSelectedChat(null);
            setSelectedChatName(null);
            setSelectedQuestion(null);
          }}>
            <Text style={[styles.tabItem, activeTab === "tab1" ? styles.activeTab : {}]}>Чаты</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            setActiveTab("tab2");
            setSelectedChat(null);
            setSelectedChatName(null);
            setSelectedQuestion(null);
            setOpenQuestion(false);
          }}>
            <Text style={[styles.tabItem, activeTab === "tab2" ? styles.activeTab : {}]}>Вопросы</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            setActiveTab("tab5");
            setSelectedChat(null);
            setSelectedChatName(null);
            setSelectedQuestion(null);
          }}>
            <Text style={[styles.tabItem, activeTab === "tab5" ? styles.activeTab : {}]}>Счетчик</Text>
          </TouchableOpacity>
        </View>
      )}

      {activeTab === "tab1" && !openChat && !selectedChat ? <ChatList {...{ timezone, platform, chats, selectedChat, openChat, setOpenChat, setSelectedChatName, setSelectedChatStatus, setSelectedChat }} /> : null}
      {activeTab === "tab1" && openChat && selectedChat ? <Chat {...{ selectedChatStatus, selectedChat, timezone, openChat, setOpenChat, setSelectedChatName, setSelectedChat, selectedChatName, platform, psyid, chats }} /> : null}
      {activeTab === "tab2" && !openQuestion && !selectedQuestion ? <QuestionList {...{ timezone, platform, questions, openQuestion, setOpenQuestion, setSelectedQuestionName, setSelectedQuestion }} /> : null}
      {activeTab === "tab2" && openQuestion ? <Question {...{ timezone, selectedQuestion, platform, psyid, selectedQuestionName, openQuestion, setOpenQuestion, setSelectedQuestionName, setSelectedQuestion, questions }} /> : null}
      {activeTab === "tab6" && !openChat ? <PaymentList {...{ totalToPayChats, totalToPayQuestions }} /> : null}
      {activeTab === "tab5" && !openChat ? <Settings {...{ totalToPayChats, totalToPayQuestions, platform, psyid, timezone, setLogged }} /> : null}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
  },
  tabBar: {
    flexDirection: "row",
    justifyContent: 'space-evenly',
    backgroundColor: "#f0f3f7",
    paddingBottom: 10,
    width: "100%",
    alignSelf: "center",
    borderTopWidth: 1,
    borderTopColor: "#ddd",
  },
  tabItem: {
    padding: 10,
    fontSize: 16,
  },
  activeTab: {
    fontWeight: "bold",
    color: "#000",
  },
});

export default MainScreen;
