import React, { useState } from "react";
import { View, Text, TextInput, StyleSheet, Platform } from "react-native";
import firebase from "firebase/app";
import { getReactNativePersistence } from "firebase/auth/react-native"
import ReactNativeAsyncStorage from '@react-native-async-storage/async-storage';
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import RoundedButton from "./RoundedButton.js";
import { auth } from "./db.js";
const LoginScreen = ({ setUserId }) => {
  let localPersistence = Platform.OS !== "web" ?
    getReactNativePersistence(ReactNativeAsyncStorage) :
    browserSessionPersistence;

  setPersistence(auth, localPersistence).catch((error) => {
    console.error("Persistence setting failed:", error);
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const handleLogin = () => {
    // console.log("email " + email + " password " + password);
    setPersistence(auth, localPersistence)
      .then(() => {
        // console.log("setPersistence "+Platform.OS)
        return signInWithEmailAndPassword(auth, email, password);
      })
      .then((userCredential) => {
        const user = userCredential.user;
        // console.log("user " + user.uid);
        setUserId(user.uid);
      })
      .catch((error) => {
        const errorMessage = error.message;
        // console.log("Error:", errorMessage);
        setError(errorMessage);
      });
  };


  return (
    <View style={styles.container}>
      <Text style={styles.title}>Вход в аккаунт</Text>
      {error && <Text style={styles.error}>{error}</Text>}
      <TextInput
        style={styles.input}
        value={email}
        onChangeText={setEmail}
        placeholder="Email"
        autoCapitalize="none"
      />
      <TextInput
        style={styles.input}
        value={password}
        onChangeText={setPassword}
        placeholder="Пароль"
        secureTextEntry
      />
      <RoundedButton style={styles.grantMediaButton} text={"Войти"} onPress={handleLogin} />
    </View>
  );
};

const styles = StyleSheet.create({
  grantMediaButton: {
    width: 100,
    borderRadius: 15,
    backgroundColor: "#6b69ff",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  error: {
    color: "red",
    marginBottom: 16,
  },
  input: {
    width: "40%",
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 8,
    padding: 8,
    borderRadius: 10,
  },
});

export default LoginScreen;
