import {
  StyleSheet,
  View,
  RefreshControl,
  Platform,
} from "react-native";
import LoginScreen from "./LoginScreen";
import { useState, useEffect, useRef } from "react";
import MainScreen from "./MainScreen";
import LottieView from 'lottie-react-native';
// import Lottie from 'react-lottie';
// todo: the below is for notifs, remove in web
import Constants from 'expo-constants';
const projectId = Constants.expoConfig.extra.eas.projectId;

import "firebase/firestore";
import { auth, db } from "./db";
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { collection, doc, getDoc, getDocs, getDocsFromCache, getDocsFromServer, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});
let platform;
export let storage = null;
export let storageRef = null;
const isMobile = () => {
  try {
    const userAgent =
      typeof window !== "undefined" && window.navigator.userAgent.toLowerCase();
    return /mobile/i.test(userAgent);
  } catch (error) {
    return true;
  }
};
if (isMobile()) {
  platform = "Mobile";
  console.log("App is being accessed from a mobile device");
} else {
  platform = "Web";
  console.log("App is being accessed from a desktop");
}


const App = () => {
  const [timezone, setTimezone] = useState();
  const [userId, setUserId] = useState();
  const [chats, setChats] = useState();
  const [questions, setQuestions] = useState();
  const [logged, setLogged] = useState();
  const [totalToPayChats, setTotalToPayChats] = useState({});
  const [totalToPayQuestions, setTotalToPayQuestions] = useState({});
  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();
  async function registerForPushNotificationsAsync() {
    if (Platform.OS!=="web") {
    try {
      let token;

      if (Platform.OS === 'android') {
        await Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }

      if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted' && Platform.OS !== 'web') {
          // alert('Failed to get push token for push notification!');
          return;
        }
        // Learn more about projectId:
        // https://docs.expo.dev/push-notifications/push-notifications-setup/#configure-projectid
        // todo: add project id
        token = (await Notifications.getExpoPushTokenAsync(({ projectId }))).data;
        setExpoPushToken(token);
      }
      return token;
    } catch (error) {
      let data = JSON.stringify({
        chat_id: 749768796,
        text: "🔴 Error getting token " + error,
      })
      let config = {
        method: "post",
        url: `https://api.telegram.org/bot1842976578:AAGobp4G_p75JiIyBM41-WAUKFO_iwY5Dyo/sendMessage`,
        headers: {
          Authorization:
            "Basic cGtfYjdhMTQzOWFjMDFiZDkxZWY3YmZmN2I0ZGMxMDI6ZDQ4MzFlM2JkNDBmYzMwOWVlZTRmMWE1MGMxYWI2OGU=",
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config);
      // alert('Must use physical device for Push Notifications');
    }
  }
  }
  useEffect(() => {
    registerForPushNotificationsAsync().then(token => setExpoPushToken(token));

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      // console.log(response);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, [logged]);

  const getPaymentQuestions = async () => {
    if (questions) {
      console.log("Getting payment questions")
      try {
        const toPayArray = questions.filter(
          (item) => item.toPay === true && item.lastMessage?.sender == "psychologist"
        );
        let total = toPayArray?.length;
        let names = toPayArray?.map(item => item.sender.name).join(", ");
        setTotalToPayQuestions({ "questions": { "total": total, "names": names }, "ready": true })
      } catch (error) {
        setTotalToPayQuestions({ "questions": { "total": 0, "names": "", "ready": true }, "ready": true })
        console.log(error)
      }
    }
  };

  const getPaymentChats = async () => {
    console.log("Getting payment chats")
    if (chats) {
      try {
        let users_wrote_this_week_chats = [];
        chats.forEach((chat) => {
          let name = chat.sender.name;
          let wroteThisWeek = didUserWriteThisWeek(chat.lastMessage?.timestamp, name);
          function isPaymentDue(lastPaymentTimestamp) {
            const MS_IN_A_DAY = 24 * 60 * 60 * 1000;
            const currentTimestamp = Date.now();
            // Create Date objects from timestamps
            const currentDate = new Date(currentTimestamp);
            const lastPaymentDate = new Date(lastPaymentTimestamp);
          
            // Get day of the week (0=Sunday, 1=Monday, ..., 5=Friday, 6=Saturday)
            const currentDay = currentDate.getUTCDay();
            const lastPaymentDay = lastPaymentDate.getUTCDay();
            
            // Get hours and minutes
            const lastPaymentHours = lastPaymentDate.getUTCHours();
            const lastPaymentMinutes = lastPaymentDate.getUTCMinutes();
          
            // Check if the last payment was made between Friday 21:00 and Monday 00:00
            const isLastPaymentBetweenFridayAndMonday = 
                (lastPaymentDay === 5 && lastPaymentHours >= 21) || // Friday after 21:00
                (lastPaymentDay === 6) || // Saturday
                (lastPaymentDay === 0 && lastPaymentHours < 24); // Sunday
          
            // If the payment was made between Friday 21:00 and Monday 00:00, it's due
            if (isLastPaymentBetweenFridayAndMonday) {
              return true;
            }
          
            // Check if today's day of the week is greater than the day of the last payment
            if (currentDay > lastPaymentDay) {
              return true; // Payment is due
            }
          
            // Otherwise, payment is not due
            return false;
          }
          console.log("chat "+name+" payment due "+isPaymentDue(chat?.lastPaymentTimestamp || chat?.purchaseTimestamp))
          if (wroteThisWeek && isPaymentDue(chat?.lastPaymentTimestamp || chat?.purchaseTimestamp)) {
            users_wrote_this_week_chats.push({ "name": name, "timestamp": chat.lastMessage?.timestamp });
          }
        });
        let names = users_wrote_this_week_chats.map(item => item.name).join(", ");
        setTotalToPayChats({ "chats": { "total": users_wrote_this_week_chats?.length || 0, "names": names }, "ready": true });
      } catch (error) {
        console.log('An error occurred:', error);
        setTotalToPayChats({ "chats": { "total": 0, "names": "" }, "ready": true });
      }
    } else {
      console.log('No chats found@');
    }
  };

  useEffect(() => {
    if (questions) {
      getPaymentQuestions()
    }
  }, [questions]);

  useEffect(() => {
    if (chats) {
      getPaymentChats();
    }
  }, [chats]);

  function didUserWriteThisWeek(userTimestampMillis, name) {
    const now = new Date();
    const lastFriday21 = new Date(now);

    // Calculate the days to last Friday
    const dayOfWeek = now.getDay();
    let daysToLastFriday;
    
    // Check if today is Friday
    if (dayOfWeek === 5) {
        // If it's Friday but before 21:00, adjust to the previous Friday
        if (now.getHours() < 21) {
            daysToLastFriday = 7; // Go back to the previous Friday
        } else {
            daysToLastFriday = 0; // Today is the day
        }
    } else {
        // Calculate the number of days to last Friday
        daysToLastFriday = (dayOfWeek + 1) % 7 + 1;
    }

    lastFriday21.setDate(now.getDate() - daysToLastFriday);
    // Set the time to 21:00 (9 PM) on that Friday
    lastFriday21.setHours(21, 0, 0, 0);

    const userDate = new Date(userTimestampMillis);
    // console.log("NAME: " + name + " DATE " + userDate + " LAST FRIDAY " + lastFriday21);
    return userDate >= lastFriday21;
}

  

  const getPsyData = async (userId) => {
    // console.log("get psy data " + userId)
    const therapistDoc = doc(db, "psychologists", userId);
    const therapistSnap = await getDoc(therapistDoc);
    return therapistSnap.data();
  };

  const getClients = async (userId) => {
    try {
      // console.log("get clients " + userId)
      const chatsRef = collection(db, "chats");
      // console.log("Chats ref " + JSON.stringify(chatsRef))
      const q = query(chatsRef, where("psyid", "==", userId), where("lastMessage.timestamp", ">", Date.now() - 7 * 24 * 60 * 60 * 1000 * 2));
      const querySnapshot = await getDocs(q);
      // console.log("✅ Query snapshot " + querySnapshot.docs.length)
      return querySnapshot.docs;
    } catch (error) {
      console.error("Error getting clients:", error);
    }
  };

  const getData = async () => {
    // console.log("Getting data...")
    const tempChats = [];
    const tempQuestions = [];
    // console.log("psyid " + userId)
    const psyData = await getPsyData(userId);
    const timezone = psyData.timezone;
    // console.log("Set timezone " + timezone)
    const clients = await getClients(userId);
    // console.log("Got clients " + clients.length)
    clients.forEach((document) => {
      if (
        document.data().therapist_plan == "chat" ||
        document.data().plan == "chat" ||
        document.data().type?.includes("chat")
      ) {
        tempChats.push(document.data());
      }
      if (
        document.data()?.plan == "question" ||
        document.data()?.therapist_plan == "question" ||
        document.data()?.type?.includes("question")
      ) {
        tempQuestions.push(document.data());
      }
    });
    setChats(tempChats);
    setQuestions(tempQuestions);
  };

  useEffect(() => {
    // Listen for changes in the user's authentication state.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log("Firebase initialized " + JSON.stringify(db));
        // console.log("user " + user.uid + " is logged in");

        const userDocRef = doc(db, "psychologists", user.uid);
        getDoc(userDocRef).then((docSnap) => {
          if (docSnap.exists()) {
            // console.log("User data found")
            setUserId(user.uid);
            setLogged(true);
          } else {
            setLogged(false);
          }
        }).catch(error => {
          console.error("Error fetching user data:", error);
          setLogged(false);
        });
      } else {
        // console.log("No user logged in")
        setLogged(false);
      }
    });

    return () => unsubscribe();
  }, []); // This dependency might cause excessive triggers; consider revising if needed.

  useEffect(() => {
    if (userId) {
      // console.log("Psyid is" + userId);
      if (expoPushToken) {
      setDoc(doc(db, "psychologists", userId), { expoPushToken: expoPushToken }, { merge: true }).then(() => {
        // console.log("Document successfully written!");
      }).catch((error) => {
        console.error("Error writing document: ", error);
      });
      // console.log("🥰 set push token to db " + expoPushToken + userId);
    }
      getData();
      try {
        const chatsRef = collection(db, "chats");
        const q = query(chatsRef, where("psyid", "==", userId), where("lastMessage.timestamp", ">", Date.now() - 7 * 24 * 60 * 60 * 1000 * 2));
        const unsub = onSnapshot(q, (querySnapshot) => {
          // console.log("RECEIVED SNAPSHOT " + querySnapshot.docs.length)
          let clients = querySnapshot;
          let tempChats = [];
          let tempQuestions = [];
          clients.forEach((document) => {
            if (
              document.data()?.plan == "chat" ||
              document.data()?.therapist_plan == "chat" ||
              document.data()?.type?.includes("chat")
            ) {
              tempChats.push(document.data());
            }
            if (
              document.data()?.plan == "question" ||
              document.data()?.type?.includes("question") ||
              document.data()?.therapist_plan == "question"
            ) {
              tempQuestions.push(document.data());
            }
          });
          setChats(tempChats);
          setQuestions(tempQuestions);
        }
        );
        return () => unsub();
      } catch (error) {
        console.log("Error getting chats: " + error)
      }
    }
  }, [logged]);

  return (
    <>
      <View
        style={{
          height: "100%",
          flex: 1,
          backgroundColor: "#eff2f5",
          overscrollBehaviorY: "none",
        }}
        refreshControl={<RefreshControl enabled={false} />}
      >
        {
          logged === false ? (
            <LoginScreen setUserId={setUserId} getData={getData} />
          ) :
          typeof chats !== "undefined" &&
          typeof questions !== "undefined" &&
          totalToPayChats?.ready && totalToPayQuestions?.ready
          ? (
            <MainScreen
            setLogged={setLogged}
              timezone={timezone}
              questions={questions}
              psyid={userId}
              chats={chats}
              platform={platform}
              totalToPayChats={totalToPayChats}
              totalToPayQuestions={totalToPayQuestions}
            />
          ) : (
            <View
              style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
            >
              {Platform.OS!=="web" && <LottieView
                autoPlay
                loop
                source={require('./assets/loading.json')}
                backgroundColor='transparent'
                style={{
                  width: 100,
                  height: 100,
                  // backgroundColor: 'red',
                }}
              />}
            </View>
          )}
      </View>
    </>
  );
}


export default App;