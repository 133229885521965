import React, { useState } from "react";
import {
  Dimensions,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform, // Import Platform to determine the current platform
} from "react-native";
import { ScrollView } from "react-native";

function getDayOfWeek(timestamp) {
  const date = new Date(timestamp);
  const dayOfWeekNumber = date.getDay();
  const daysOfWeek = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
  return daysOfWeek[dayOfWeekNumber];
}

const ChatList = ({
  timezone,
  setSelectedChatName,
  platform,
  selectedChat,
  chats,
  setOpenChat,
  setSelectedChat,
  setSelectedChatStatus,
  openChat,
}) => {

  function customSort(a, b) {
    const unreadAndActiveA = !a.read && a?.status === "Active";
    const unreadAndActiveB = !b.read && b?.status === "Active";
    const readAndActiveA = a.read && a?.status === "Active";
    const readAndActiveB = b.read && b?.status === "Active";

    if (unreadAndActiveA && !unreadAndActiveB) {
      return -1;
    }

    if (!unreadAndActiveA && unreadAndActiveB) {
      return 1;
    }

    if (unreadAndActiveA && unreadAndActiveB) {
      return b?.lastMessage?.timestamp - a?.lastMessage?.timestamp;
    }

    if (readAndActiveA && !readAndActiveB) {
      return -1;
    }

    if (!readAndActiveA && readAndActiveB) {
      return 1;
    }

    if (readAndActiveA && readAndActiveB) {
      return b?.lastMessage?.timestamp - a?.lastMessage?.timestamp;
    }

    return b?.lastMessage?.timestamp - a?.lastMessage?.timestamp;
  }

  const screenHeight = Dimensions.get("window").height;
  const sortedChats = chats.sort(customSort);
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => setIsHovered(!isHovered);
  const chatItemStyle = [
    styles.chatItem,
    {
      marginBottom: platform === "Web" ? "1%" : "5%", // Reduced spacing for Web
    },
  ];

  return (
    <ScrollView
      style={{
        maxHeight: screenHeight,
        marginTop: platform === "Web" ? "1%" : "10%",
      }}
      contentContainerStyle={{
        paddingHorizontal: '5%', // Adjust this value as needed to control horizontal padding
        alignItems: 'center', // Centers children horizontally in the container
      }}
    >
      {sortedChats.length > 0 ? (
        sortedChats.map((chat) => {
          let paymentTimestamp = chat?.lastPaymentTimestamp ? chat?.lastPaymentTimestamp : chat?.purchaseTimestamp;
          return chat.lastMessage !== undefined ? (
            <TouchableOpacity
              disabled={chat.status == "Cancelled" ? true : false}
              key={chat.id}
              style={[
                styles.chatItem,
                {
                  marginBottom: platform === "Web" ? "1%" : "5%",
                  opacity: chat?.status == "Cancelled" ? 0.5 : 1,
                },
              ]}
              onPress={() => {
                if (chat?.status == "Cancelled") return;
                console.log("chat clicked "+chat.id)
                setOpenChat(!openChat);
                setSelectedChatName(chat.sender.name);
                setSelectedChat(chat.id);
                setSelectedChatStatus(chat.status);
              }}
            >
              <View
                style={
                  openChat == true && selectedChat == chat.id
                    ? styles.selected
                    : styles.info
                }
              >
                <View
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                  style={[styles.nameContainer, { flexDirection: "row" }]}>
                  <Text style={styles.name}>{chat.sender.name}</Text>
                  {isHovered && <Text style={{ marginLeft: 5, fontSize: 10, color: "gray" }}>{getDayOfWeek(paymentTimestamp)}</Text>}
                  {!chat.read && <View style={styles.unreadIndicator} />}
                  <Text style={styles.time}>
                    {!isNaN(new Date(chat?.lastMessage?.timestamp).getTime())
                      ? new Date(
                        Number(chat?.lastMessage?.timestamp)
                      ).toLocaleString("ru-RU", {
                        day: "2-digit",
                        month: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZone: timezone,
                      })
                      : null}
                  </Text>
                </View>
                <Text style={styles.message} numberOfLines={2}>
                  {chat?.lastMessage?.text}
                </Text>
              </View>
            </TouchableOpacity>
          ) : null;
        })
      ) : (
        <TouchableOpacity style={styles.info}>
          <Text>Пока нет чатов.</Text>
        </TouchableOpacity>
      )}
    </ScrollView>
  );
};



const styles = StyleSheet.create({
  notPaidIndicator: {
    position: "absolute",
    bottom: 20,
    right: 22,
    backgroundColor: "rgba(169, 169, 169, 0.5)",
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingVertical: 2,
  },
  notPaidText: {
    fontSize: 10,
    color: "white",
  },
  chatItem: {
    flexDirection: "row",
    width: "90%", // Define the width to help center the items
    alignItems: "center",
  },
  info: {
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
    width: "100%",
    height: "auto",
    padding: 22,
    borderRadius: 20,
    alignSelf: 'center', // Center the info boxes within their container
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  unreadIndicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "blue",
    marginRight: 8,
    marginLeft: 8,
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  
  selected: {
    backgroundColor: "#eff2f5",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px",
    width: "90%",
    height: "auto",
    padding: 22,
    borderRadius: 20,
    marginLeft: 8,
  },
  name: {
    fontWeight: "bold",
    marginBottom: 3,
  },
  message: {
    color: "gray",
    maxWidth: "80%",
    lineHeight: 15,
  },
  time: {
    position: "absolute",
    right: 0,
    width: 50,
    color: "gray",
    fontSize: 8,
  },
});

export default ChatList;
