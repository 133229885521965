// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, initializeAuth } from "firebase/auth";
import { getReactNativePersistence } from "firebase/auth/react-native";
import ReactNativeAsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from "react-native";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD4DsVepp6Fz0rMKVAI5ShZ-TiHKNtlc3g",
  authDomain: "leatest2-13bae.firebaseapp.com",
  projectId: "leatest2-13bae",
  storageBucket: "leatest2-13bae.appspot.com",
  messagingSenderId: "854347260836",
  appId: "1:854347260836:web:0c02013db96fbd58149731",
  measurementId: "G-PVYJZRFGK5"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const db = getFirestore(app);
const storage = getStorage(app);
const auth = Platform.OS!=="web" ? initializeAuth(app, {
  persistence: getReactNativePersistence(ReactNativeAsyncStorage)
}) : getAuth(app);

// Export the necessary Firebase services
export { db, storage, auth };
