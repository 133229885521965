import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Tooltip from 'react-native-walkthrough-tooltip';

const PaymentList = ({
    totalToPayChats,
    totalToPayQuestions,
}) => {
    
    return (
        <View style={styles.container}>
            <View style={styles.box}>
                <Text style={styles.headerText}>Чаты: {totalToPayChats?.chats?.names}</Text>
                <Text style={styles.headerText}>Вопросы: {totalToPayQuestions?.questions?.names}</Text>
                <Text style={styles.infoText}>Пожалуйста, осуществляйте сверку активных клиентов в пятницу после 20:00.</Text>
            </View>
            <View style={styles.box}>
                <Text style={styles.paymentText}>
                    К оплате: {totalToPayChats?.chats?.total * 550 + totalToPayQuestions?.questions?.total * 80} 
                </Text>
                <Text style={styles.detailText}>Чаты: {totalToPayChats?.chats?.total}</Text>
                <Text style={styles.detailText}>Вопросы: {totalToPayQuestions?.questions?.total}</Text>
            </View>
        </View>
    );
}

const styles = {
    container: {
        marginTop: '10%', 
        alignContent: 'center', 
        alignItems: 'center'
    },
    box: {
        width: 350, 
        alignSelf: "center", 
        borderRadius: 10, 
        backgroundColor: "white", 
        padding: '10%',
        marginBottom: 30
    },
    headerText: {
        fontFamily: "System", // Use San Francisco font on Apple devices
        color: '#333',
        fontWeight: 'bold',
        marginBottom: 5
    },
    infoText: {
        fontFamily: "System",
        fontSize: 10,
        color: '#888',
        fontStyle: 'italic'
    },
    paymentText: {
        fontFamily: "System", 
        fontSize: 14, 
        fontWeight: 'bold', 
        marginBottom: '2%'
    },
    detailText: {
        fontFamily: "System", 
        fontSize: 12, 
        fontWeight: 'normal'
    }
};

export default PaymentList;
