import React from "react";
import { Text, View } from "react-native";
import Selector from "./Selector";
import RoundedButton from "./RoundedButton";
import { auth } from "./db";
import PaymentList from "./PaymentList";
import { TouchableOpacity } from "react-native";

const Settings = ({ platform, setLogged, totalToPayChats, totalToPayQuestions, psyid }) => {
  return (
    <View style={styles.container}>
      <View style={styles.sidebar}>
        <PaymentList totalToPayChats={totalToPayChats} totalToPayQuestions={totalToPayQuestions} psyid={psyid} />
        <TouchableOpacity
          onPress={async () => { await auth.signOut(), setLogged(false) }}
          style={styles.logoutButton}>
          <Text style={styles.buttonText}>Выйти</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = {
  container: {
    alignSelf: "center",
    flex: 1,
  },
  sidebar: {
    width: "100%",
    flexDirection: 'column',  // Ensures the children are arranged in a column
    alignItems: 'center',     // Align children in the center horizontally
  },
  logoutButton: {
    marginLeft: '0%',
    alignSelf: 'center',      // Align the button in the center horizontally
    marginTop: 20,            // Adds space between the PaymentList and the button
  },
  buttonText: {
    fontSize: 16,
    color: '#000',            // Ensures the text is visible depending on your app theme
  },
};

export default Settings;
